import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import useGlobalStyles from "../../styles";

const TechnicalSpec: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left",
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1.5rem",
      height: "100%",
    },
    highlight: {
      color: "#1976d2",
      fontWeight: "bold",
    },
    listItem: {
      marginBottom: "0.5rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.title}>Technical Specifications</Typography>
      <Typography sx={styles.tagline}>
        Edge Computing Meets Precision Engineering and AI
      </Typography>

      {/* Edge Computing Section */}
      <Box sx={styles.section}>
        <Typography variant="h3" sx={styles.sectionTitle}>
          Edge Computing Architecture
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Distributed Processing</Typography>
              <ul>
                <li style={styles.listItem}>
                  Web app utilizes edge computing for maximum efficiency
                </li>
                <li style={styles.listItem}>
                  Processing is performed as close to the data source as
                  possible
                </li>
                <li style={styles.listItem}>
                  AI processing on central servers only when necessary, reducing
                  latency and bandwidth usage
                </li>
                <li style={styles.listItem}>
                  Each blending module equipped with its own Unix processor for
                  localized control
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Physical Blender Section */}
      <Box sx={styles.section}>
        <Typography variant="h3" sx={styles.sectionTitle}>
          Blender
        </Typography>
        <Grid container spacing={3}>
          {/* Output Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Throughput</Typography>
              <ul>
                <li style={styles.listItem}>3 to 5 pounds per minute</li>
                <li style={styles.listItem}>180 to 300 pounds per hour</li>
                <li style={styles.listItem}>
                  1,440 to 2,400 pounds per shift (8 hours)
                </li>
                <li style={styles.listItem}>4,000 to 7,000 pounds per day</li>
              </ul>
            </Paper>
          </Grid>

          {/* Requirements Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Requirements</Typography>
              <ul>
                <li style={styles.listItem}>
                  <strong>Power Supply:</strong> 120V, 100 Amp
                </li>
                <li style={styles.listItem}>
                  <strong>Air Supply:</strong> 90 psi
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Modular System Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Modular System</Typography>
              <ul>
                <li style={styles.listItem}>
                  6 modules, each containing 3 silo/scales
                </li>
                <li style={styles.listItem}>
                  Each module equipped with its own Unix processor for edge
                  computing
                </li>
                <li style={styles.listItem}>
                  <strong>Serviceability:</strong> Modules can be easily
                  exchanged for quick return to service
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Silo Capacity Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Silo Capacity</Typography>
              <ul>
                <li style={styles.listItem}>
                  18 silos, total capacity of 900 pounds
                </li>
                <li style={styles.listItem}>
                  Weight is monitored and passed to the corresponding processor
                  for real-time control
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Refill System Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Refill System</Typography>
              <ul>
                <li style={styles.listItem}>
                  <strong>Operator Notification:</strong> Alerts when a silo
                  needs to be refilled
                </li>
                <li style={styles.listItem}>
                  <strong>Automated Interface:</strong> Manages filling by
                  notifying when to start and stop
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Material Specification */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Material</Typography>
              <Typography>
                The blender system is constructed using high-grade 304 stainless
                steel for durability, corrosion resistance, and easy cleaning.
              </Typography>
            </Paper>
          </Grid>

          {/* Accuracy Section */}
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Accuracy</Typography>
              <Typography sx={styles.highlight}>
                Blending accuracy: ±1.50%
              </Typography>
              <Typography>
                Our state-of-the-art blending system ensures precise
                measurements and consistent results, with an accuracy of plus or
                minus 1.50 percent for each coffee in the blend.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Front-facing Web/Device Application Section */}
      <Box sx={styles.section}>
        <Typography variant="h3" sx={styles.sectionTitle}>
          Front-facing Web/Device Application
        </Typography>

        <Grid container spacing={3}>
          {/* App Integration Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">
                App Integration and Processing
              </Typography>
              <ul>
                <li style={styles.listItem}>
                  Users enter their flavor profile and place orders through the
                  app
                </li>
                <li style={styles.listItem}>
                  Edge computing architecture maximizes local processing
                </li>
                <li style={styles.listItem}>
                  AI processing on central servers only when necessary
                </li>
                <li style={styles.listItem}>
                  The app can be local or cloud-based, supporting all major
                  cloud providers
                </li>
                <li style={styles.listItem}>
                  Responsive design ensures compatibility with various devices
                  and screen sizes
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Updated Flavor Profile Parameters Section */}
          <Grid item xs={12} md={6}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">
                Customizable Flavor Profile System
              </Typography>
              <Typography paragraph>
                Our highly flexible flavor profile system allows for easy
                customization:
              </Typography>
              <ul>
                <li style={styles.listItem}>
                  Flavor parameters can be easily added, modified, or removed
                </li>
                <li style={styles.listItem}>
                  Default parameters include: Mouthfeel, Acidity,
                  Caramelization, Fruitiness, Floral qualities
                </li>
                <li style={styles.listItem}>
                  Customize parameters to match your specific coffee offerings
                  and customer preferences
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* New Customizable Web App Section */}
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Customizable Web App</Typography>
              <Typography paragraph>
                Our web app is designed for maximum flexibility and brand
                alignment:
              </Typography>
              <ul>
                <li style={styles.listItem}>
                  Comprehensive theming options to match your brand identity
                </li>
                <li style={styles.listItem}>
                  Customizable images, colors, text, and layout
                </li>
                <li style={styles.listItem}>
                  Flexible placement of UI elements to optimize user experience
                </li>
              </ul>
            </Paper>
          </Grid>

          {/* Network Security Section */}
          <Grid item xs={12}>
            <Paper sx={styles.paper}>
              <Typography variant="h5">Network Security</Typography>
              <ul>
                <li style={styles.listItem}>
                  The system operates on a secure local private network
                </li>
                <li style={styles.listItem}>
                  Blend recipes are entered via a QR code, keeping them
                  disconnected from the frontend for enhanced security
                </li>
                <li style={styles.listItem}>
                  Edge computing architecture minimizes data transfer, enhancing
                  security
                </li>
                <li style={styles.listItem}>
                  Regular security audits and updates ensure the system remains
                  protected against potential vulnerabilities
                </li>
              </ul>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TechnicalSpec;
