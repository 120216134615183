import React from "react";
import { Box, Typography, Button, Grid, Paper } from "@mui/material";
import useGlobalStyles from "../../styles";

const DemoPage: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left",
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1.5rem",
      height: "100%",
    },
    iframe: {
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
      borderRadius: "8px",
      width: "100%",
      height: "1000px",
      border: "none",
    },
    ctaButton: {
      marginTop: "2rem",
      padding: "1rem 2rem",
      fontSize: "1.2rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Experience PurfectBlend in Action
      </Typography>
      <Typography sx={styles.tagline}>
        Discover how AI revolutionizes custom coffee blending
      </Typography>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Interactive Demo
            </Typography>
            <Typography paragraph>
              Explore our interactive demo to experience the power of
              PurfectBlend's AI-driven custom coffee blending system. This demo
              showcases:
            </Typography>
            <ul>
              <li>User-friendly flavor profile selection</li>
              <li>Real-time blend creation based on your preferences</li>
              <li>Visualization of the blending process</li>
              <li>Sample output of custom blend recipes</li>
            </ul>
            <Typography paragraph>
              Note: This demo is a simplified version of our full system. For a
              comprehensive demonstration tailored to your business needs,
              please contact our sales team.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              How to Use the Demo
            </Typography>
            <ol>
              <li>Select your preferred flavor profile attributes</li>
              <li>Adjust the sliders to fine-tune your preferences</li>
              <li>Click "Create Blend" to see your custom recipe</li>
              <li>Explore different combinations to see how the AI adapts</li>
            </ol>
            <Typography paragraph>
              Remember, this demo showcases just a fraction of PurfectBlend's
              capabilities. Our full system offers even more customization
              options and integrates seamlessly with your existing coffee
              inventory.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              PurfectBlend Demo
            </Typography>
            <Box
              component="iframe"
              src="https://pbc-front.uw.r.appspot.com/"
              sx={styles.iframe}
              title="PurfectBlend Demo"
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Next Steps
            </Typography>
            <Typography paragraph>
              Ready to see how PurfectBlend can transform your coffee business?
              Our team is here to help you explore the full potential of our
              AI-powered blending system.
            </Typography>
            <Box sx={{ textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                href="/get-started"
                sx={styles.ctaButton}
              >
                Request a Full Demo
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DemoPage;
