import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import useGlobalStyles from "../../styles";

const AboutUs: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left",
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1.5rem",
      height: "100%",
    },
    highlight: {
      color: "#1976d2",
      fontWeight: "bold",
    },
    listItem: {
      marginBottom: "0.5rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        About PurfectBlend
      </Typography>
      <Typography sx={styles.tagline}>
        Experience. Expertise. Innovation.
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h4" sx={styles.sectionTitle}>
              Our Story
            </Typography>
            <Typography paragraph>
              PurfectBlend was born from a passion for exceptional coffee and a
              vision to revolutionize the industry. We saw an opportunity to
              bring together cutting-edge AI technology and precision
              engineering to create a system that would allow coffee businesses
              to offer truly personalized blends at scale.
            </Typography>
            <Typography paragraph>
              Our journey began with a simple question: "What if we could create
              the perfect cup of coffee for every individual?" This led us to
              develop our innovative AI-powered blending system, which has since
              transformed the way coffee businesses operate and delight their
              customers.
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Our Expertise
            </Typography>
            <Typography paragraph>
              Our team brings together decades of experience across a variety of
              fields, including:
            </Typography>
            <ul>
              <li style={styles.listItem}>
                Artificial Intelligence and Machine Learning
              </li>
              <li style={styles.listItem}>Software Development</li>
              <li style={styles.listItem}>Precision Engineering</li>
              <li style={styles.listItem}>Coffee Roasting and Blending</li>
              <li style={styles.listItem}>Manufacturing and Quality Control</li>
              <li style={styles.listItem}>Supply Chain Management</li>
              <li style={styles.listItem}>Customer Experience Design</li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Our Values
            </Typography>
            <ul>
              <li style={styles.listItem}>
                <strong>Innovation:</strong> We continuously push the boundaries
                of what's possible in coffee blending.
              </li>
              <li style={styles.listItem}>
                <strong>Quality:</strong> We are committed to delivering
                excellence in every aspect of our product and service.
              </li>
              <li style={styles.listItem}>
                <strong>Sustainability:</strong> We strive to minimize waste and
                promote responsible practices in the coffee industry.
              </li>
              <li style={styles.listItem}>
                <strong>Collaboration:</strong> We work closely with our clients
                to ensure their success and growth.
              </li>
              <li style={styles.listItem}>
                <strong>Transparency:</strong> We believe in open communication
                and honesty in all our business dealings.
              </li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle}>
              Our Commitment
            </Typography>
            <Typography paragraph>
              At PurfectBlend, we are dedicated to:
            </Typography>
            <ul>
              <li style={styles.listItem}>
                Empowering coffee businesses to offer personalized experiences
                at scale
              </li>
              <li style={styles.listItem}>
                Continuously improving our AI algorithms and blending technology
              </li>
              <li style={styles.listItem}>
                Providing exceptional support to ensure our clients' success
              </li>
              <li style={styles.listItem}>
                Fostering innovation in the coffee industry
              </li>
              <li style={styles.listItem}>
                Contributing to sustainable and ethical practices in coffee
                production and distribution
              </li>
            </ul>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={styles.paper}>
            <Typography variant="h5" sx={styles.sectionTitle} align="center">
              Join Us in Revolutionizing the Coffee Experience
            </Typography>
            <Typography paragraph>
              We invite you to partner with PurfectBlend and be at the forefront
              of the custom coffee revolution. Together, we can create
              unforgettable coffee experiences that keep your customers coming
              back for more.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AboutUs;
