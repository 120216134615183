import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import useGlobalStyles from "../../styles";

const PrivacyPolicy: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();

  const styles = {
    ...globalStyles,
    container: {
      ...globalStyles.container,
      textAlign: "left",
      width: "80%", // Set the width to 80% of the page
      margin: "0 auto", // Center the container
    },
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "1.5rem",
      marginBottom: "1.5rem",
      width: "100%", // Make all paper components full width of the container
    },
    listItem: {
      marginBottom: "0.5rem",
    },
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Privacy Policy
      </Typography>
      <Typography sx={{ ...styles.tagline, textAlign: "left" }}>
        Protecting Your Information is Our Priority
      </Typography>

      <Paper sx={styles.paper}>
        <Typography paragraph>
          At PurfectBlend, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines how we collect, use, and safeguard your data when you
          use our website and services.
        </Typography>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Information We Collect
        </Typography>
        <Typography paragraph>
          We may collect the following types of information:
        </Typography>
        <List>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Personal information (e.g., name, email address, phone number) provided when you fill out our contact or inquiry forms" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Company information, including business type and size" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Information about your interest in our products and services" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Usage data and analytics collected through our website" />
          </ListItem>
        </List>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          How We Use Your Information
        </Typography>
        <Typography paragraph>
          We use the collected information for the following purposes:
        </Typography>
        <List>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="To respond to your inquiries and provide information about our products and services" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="To improve our website and services based on your feedback and usage patterns" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="To send you marketing communications about our products and services, if you've opted in to receive them" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="To comply with legal obligations and protect our rights" />
          </ListItem>
        </List>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Data Protection and Security
        </Typography>
        <Typography paragraph>
          We implement appropriate technical and organizational measures to
          protect your personal information against unauthorized access,
          alteration, disclosure, or destruction. These measures include:
        </Typography>
        <List>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Encryption of sensitive data" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Regular security assessments and updates" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Limited access to personal information by our employees on a need-to-know basis" />
          </ListItem>
        </List>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Third-Party Services
        </Typography>
        <Typography paragraph>
          We may use third-party services to help us operate our website and
          provide our services. These services may have access to your personal
          information only to perform specific tasks on our behalf and are
          obligated not to disclose or use it for any other purpose.
        </Typography>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Your Rights
        </Typography>
        <Typography paragraph>You have the right to:</Typography>
        <List>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Access, correct, or delete your personal information" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Object to or restrict the processing of your data" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Request a copy of your data in a portable format" />
          </ListItem>
          <ListItem sx={styles.listItem}>
            <ListItemText primary="Withdraw your consent at any time for marketing communications" />
          </ListItem>
        </List>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Changes to This Privacy Policy
        </Typography>
        <Typography paragraph>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page and
          updating the "last updated" date.
        </Typography>
      </Paper>

      <Paper sx={styles.paper}>
        <Typography variant="h5" sx={styles.sectionTitle}>
          Contact Us
        </Typography>
        <Typography paragraph>
          If you have any questions about this Privacy Policy or our data
          practices, please contact us at:
        </Typography>
        <Typography>
          Email: privacy@purfectblend.com
          <br />
          Address: 123 Coffee Lane, Roastville, CA 90210, USA
        </Typography>
      </Paper>

      <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
        Last updated: September 14, 2024
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
