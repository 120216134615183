/** @jsxImportSource @emotion/react */
//import styles from './styles';
import React from "react";
import { AppBar, Toolbar, Button, Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
export const Header: React.FC = () => {
  const theme = useTheme();
  // Media query for small screens
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Media query for landscape orientation
  const isLandscape = useMediaQuery("(orientation: landscape)");

  // Combine conditions: small screen and landscape
  const isSmallScreenLandscape = isSmallScreen && isLandscape;

  const styles = {
    img: {
      width: isSmallScreen ? (isSmallScreenLandscape ? "90%" : "80%") : "100%",
      margin: 8,
    },
    container: {
      display: "flex",
      marginTop: 2,
    },
    imgContainer: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      marginRight: isSmallScreen ? 0 : 2,
      padding: isSmallScreen ? 0 : "auto",
      color: theme.palette.primary.contrastText,
      fontSize: isSmallScreen ? "1.00rem" : "1.25rem",
    },
    buttonContainer: {
      display: "flex",
      flexFlow: "row wrap",
      alignItems: "center",
    },
    homeLink: {
      textDecoration: "pointer",
      Margin: "auto",
      //maxWidth: '50%',
      height: "auto",
      display: "flex",
      justifyContent: "center",
    },
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar sx={styles.buttonContainer}>
          <Button color="inherit" component={Link} to="/" sx={styles.button}>
            Home
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/about"
            sx={styles.button}
          >
            {isSmallScreen ? "Us" : "About us"}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/how-it-works"
            sx={styles.button}
          >
            {isSmallScreen ? "How" : "How It Works"}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/specs"
            sx={styles.button}
          >
            Specs
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/case_studies"
            sx={styles.button}
          >
            {isSmallScreen ? "Studies" : "Case Studies"}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/contact"
            sx={styles.button}
          >
            Contact
          </Button>

          <Button
            color="inherit"
            component={Link}
            to="/demo"
            sx={styles.button}
          >
            Demo
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/get-started"
            sx={styles.button}
          >
            {isSmallScreen ? "Begin" : "Get Started"}
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={styles.imgContainer}>
        <Link to="/" style={styles.homeLink}>
          <img src="images/header.png" alt="Header" style={styles.img} />
        </Link>
      </Box>
    </>
  );
};

export default Header;
