import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blue, brown, grey, red } from "@mui/material/colors";
import CssBaseline from "@mui/material/CssBaseline";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0c80bc",
      light: blue[100],
      dark: blue[800],
      contrastText: brown[100],
    },
    secondary: {
      main: brown[800],
      light: brown[600],
      dark: brown[900],
      contrastText: blue[800],
    },
    background: {
      default: "#ffffff",
      paper: "#d1d5c5",
    },
    text: {
      primary: grey[900],
      secondary: grey[700],
    },
    error: {
      main: red[900],
      light: red[700],
      dark: red[900],
      contrastText: grey[50],
    },
  },
  typography: {
    fontFamily: ["SFRegular", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "2.5rem",
      fontWeight: 500,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
  },
});

const rootElement = document.getElementById("root");

if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  console.error(
    "Root element not found. Make sure there's an element with the ID 'root' in your HTML."
  );
  // Consider rendering a fallback UI here
}
