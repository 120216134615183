import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Container } from "@mui/material";
import Homepage from "./components/departments/Home";
import HowItWorks from "./components/departments/How";
import CaseStudies from "./components/departments/CaseStudies";
import TechnicalSpecs from "./components/departments/Specs";
import ContactSupport from "./components/departments/Contact";
import Demo from "./components/departments/Demos";
import AboutUs from "./components/departments/AboutUs";
import GetStarted from "./components/departments/getStarted";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import PrivacyPolicy from "./components/departments/PrivacyPolicy";

const App: React.FC = () => {
  return (
    <Router>
      <CssBaseline />
      <Header />
      <Container>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/specs" element={<TechnicalSpecs />} />
          <Route path="/contact" element={<ContactSupport />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/case_studies" element={<CaseStudies />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />;
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
};

export default App;
