import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useGlobalStyles = () => {
  const theme = useTheme();

  // Media query for small screens
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Media query for landscape orientation
  const isLandscape = useMediaQuery("(orientation: landscape)");

  // Combine conditions: small screen and landscape
  const isSmallScreenLandscape = isSmallScreen && isLandscape;

  // Define your globalStyles based on the conditions
  const globalStyles = {
    container: {
      display: "flex",
      flexFlow: "column",
      textAlign: "center",
      marginBottom: "2rem",
      alignItems: "center",
      //	py: 8, // Padding Y
    },
    title: {
      fontSize: isSmallScreen ? "1.75rem" : "2.5rem", // Adapt font size based on screen size
      fontWeight: "bold",
      mb: 4, // Margin bottom
    },
    tagline: {
      fontFamily: "SFThin", // Custom font
      marginTop: 2,
      marginBottom: 2,
      fontSize: isSmallScreen ? "1.25rem" : "1.5rem",
    },
    // Add additional globalStyles as needed
  };

  return { globalStyles, isSmallScreen, isLandscape, isSmallScreenLandscape };
};

export default useGlobalStyles;
