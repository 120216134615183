import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Paper,
  Grid,
  CircularProgress,
  Snackbar,
  Alert,
  Link,
} from "@mui/material";
import emailjs from "emailjs-com";
import useGlobalStyles from "../../styles";

const GetStartedForm: React.FC = () => {
  const { globalStyles, isSmallScreen } = useGlobalStyles();
  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    phone: "",
    businessType: "",
    inquiry: [] as string[],
    units: "",
    message: "",
  });

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const styles = {
    ...globalStyles,
    section: {
      marginTop: "2rem",
    },
    sectionTitle: {
      marginBottom: "1rem",
      fontFamily: "SFBold",
      fontSize: "1.75rem",
    },
    paper: {
      padding: "2rem",
      marginTop: "2rem",
    },
    formField: {
      marginBottom: "1.5rem",
    },
    submitButton: {
      marginTop: "1rem",
      padding: "0.75rem",
      fontSize: "1.1rem",
    },
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear error when user types
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    }
  };

  const handleSelectChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    if (name === "inquiry") {
      const newValue = Array.isArray(value) ? value : [value as string];
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name as string]: value as string,
      }));
    }
    // Clear error when user selects
    if (errors[name as string]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name as string]: "" }));
    }
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.fullName) newErrors.fullName = "Full name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.businessType)
      newErrors.businessType = "Business type is required";
    if (formData.inquiry.length === 0)
      newErrors.inquiry = "Please select at least one area of interest";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const userId = process.env.REACT_APP_EMAILJS_USER_ID;

    if (!serviceId || !templateId || !userId) {
      console.error(
        "EmailJS configuration is incomplete. Please check your environment variables."
      );
      setSnackbar({
        open: true,
        message:
          "Sorry, there was a problem sending your message. Please try again later.",
        severity: "error",
      });
      setIsLoading(false);
      return;
    }

    try {
      await emailjs.sendForm(serviceId, templateId, e.currentTarget, userId);
      setSnackbar({
        open: true,
        message: "Thank you for your inquiry! We will get back to you soon.",
        severity: "success",
      });
      setFormData({
        fullName: "",
        companyName: "",
        email: "",
        phone: "",
        businessType: "",
        inquiry: [],
        units: "",
        message: "",
      });
    } catch (error) {
      console.error("EmailJS error:", error);
      setSnackbar({
        open: true,
        message:
          "Oops! Something went wrong. Please try again or contact us directly.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={styles.container}>
      <Typography variant="h2" sx={styles.title}>
        Get Started with PurfectBlend AI-Powered Blending
      </Typography>
      <Typography sx={styles.tagline}>
        Unlock the future of custom coffee blends – let AI revolutionize your
        roasting business. Your journey to the perfect blend starts here!
      </Typography>

      <Paper sx={styles.paper} elevation={3}>
        <Typography variant="h4" sx={styles.sectionTitle}>
          Request More Information
        </Typography>
        <Typography paragraph>
          Interested in bringing AI-powered custom blending to your coffee
          business? Fill out the form below, and one of our specialists will get
          in touch to discuss how PurfectBlend can transform your offerings.
        </Typography>

        <form onSubmit={sendEmail}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Full Name"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!errors.fullName}
                helperText={errors.fullName}
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Company Name"
                name="companyName"
                value={formData.companyName}
                onChange={handleInputChange}
                fullWidth
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Email Address"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                fullWidth
                required
                error={!!errors.email}
                helperText={errors.email}
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                fullWidth
                sx={styles.formField}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Business Type"
                name="businessType"
                value={formData.businessType}
                onChange={handleSelectChange}
                fullWidth
                required
                error={!!errors.businessType}
                helperText={errors.businessType}
                sx={styles.formField}
              >
                <MenuItem value="coffee_roaster">Coffee Roaster</MenuItem>
                <MenuItem value="specialty_coffee_shop">
                  Specialty Coffee Shop
                </MenuItem>
                <MenuItem value="coffee_distributor">
                  Coffee Distributor/Wholesaler
                </MenuItem>
                <MenuItem value="coffee_equipment_manufacturer">
                  Coffee Equipment Manufacturer
                </MenuItem>
                <MenuItem value="hospitality">
                  Hospitality (Hotels, Restaurants)
                </MenuItem>
                <MenuItem value="office_coffee_service">
                  Office Coffee Service Provider
                </MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                select
                label="Number of Units Interested In"
                name="units"
                value={formData.units}
                onChange={handleSelectChange}
                fullWidth
                sx={styles.formField}
              >
                <MenuItem value="1-5">1-5 Units</MenuItem>
                <MenuItem value="6-10">6-10 Units</MenuItem>
                <MenuItem value="10+">10+ Units</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="What Are You Interested In?"
                name="inquiry"
                value={formData.inquiry}
                onChange={handleSelectChange}
                fullWidth
                required
                error={!!errors.inquiry}
                helperText={errors.inquiry}
                SelectProps={{ multiple: true }}
                sx={styles.formField}
              >
                <MenuItem value="ai_blending_software">
                  AI-Powered Blending Software
                </MenuItem>
                <MenuItem value="blending_hardware">
                  Precision Blending Hardware
                </MenuItem>
                <MenuItem value="full_system_integration">
                  Full System Integration (Software + Hardware)
                </MenuItem>
                <MenuItem value="consumer_facing_app">
                  Consumer-Facing Customization App
                </MenuItem>
                <MenuItem value="training_support">
                  Training and Support
                </MenuItem>
                <MenuItem value="other">
                  Other (Please specify in message)
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="How Can We Help You?"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                multiline
                rows={4}
                fullWidth
                sx={styles.formField}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isLoading}
            sx={styles.submitButton}
          >
            {isLoading ? <CircularProgress size={24} /> : "Submit Inquiry"}
          </Button>
        </form>
        <Typography variant="body2" sx={{ mt: 2 }}>
          By submitting this form, you agree to our{" "}
          <Link href="/privacy-policy">Privacy Policy</Link>.
        </Typography>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default GetStartedForm;
